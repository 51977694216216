import { useEffect, useState } from 'react';
import { Image } from 'antd';
import httpClient from '../../utils/httpClient';
import search from '../../image/search.svg';
import triple from '../../image/triple.svg';
import imgError from '../../image/img_error.png';
import { Link } from 'react-router-dom';
import WelcomHeader from '../../components/welcomHeader.js';
import 'antd/es/image/style/css';
import './index.css';

function Chains() {
  const [chainList, setList] = useState([]);
  const [chainPriceList, setChainPriceList] = useState({});

  const fetchPrice = async (chainIdListStr) => {
    try {
      const chainPrice = await httpClient(
        'get',
        `https://api.coingecko.com/api/v3/simple/price?ids=${chainIdListStr}&vs_currencies=usd`
      );
      return chainPrice;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const listJson = await httpClient('get', `/list/list.json`);
      const chainIdLIst = listJson.map((item) => item.name);
      const priceList = await fetchPrice(chainIdLIst.toString());
      setChainPriceList(priceList);
      setList(listJson);
      // const chainList = await Promise.all(listJson.map(async item => {
      //   item.currentPrice = await fetchPrice(item.name)
      //   return item;
      // }))
      // console.log("🚀 ~ file: chains.js ~ line 35 ~ fetchData ~ chainList", chainList)
      // setList(chainList);
    };
    fetchData();
  }, []);

  return (
    <>
      <WelcomHeader />
      {/* 搜索框 */}
      <div className="flex px-16">
        <div className="flex relative">
          <input
            type="text"
            className="flex w-full rounded border border-grey-100 dark:border-[rgba(110,129,203,0.25)] border-solid bg-white dark:bg-transparent py-3 px-3 pr-9 text-grey-600 dark:text-grey-100 font-normal placeholder:font-sq placeholder:font-normal placeholder:text-sm placeholder:text-grey-300 dark:placeholder:text-grey-400 focus:outline-0"
            placeholder="Search Chain"
            maxLength="30"
          />
          <div className="absolute top-[50%] translate-y-[-50%] right-3 z-10">
            <img src={search} alt="search" />
          </div>
        </div>
      </div>
      {/* 链列表 */}
      <ul className="flex flex-col w-full mt-3 px-16">
        {chainList.length > 0 &&
          chainList.map((item) => {
            return (
              <li
                key={item.name}
                className="flex items-center w-full rounded-xl lg:rounded-none h-24 lg:border-b-[1px] border-b-grey-50 dark:border-b-grey-700 hover:bg-grey-15 dark:hover:bg-grey-500/[0.12] cursor-pointer"
              >
                <div className="flex items-center w-full">
                  <Link
                    className="flex w-full pl-3"
                    to={`/dashboard/chains/${item.name}`}
                  >
                    <div className="flex shrink-0 justify-center items-center w-[56px]">
                      <div className="flex rounded-full w-full relative">
                        <span className="box-border inline-block border-0 m-0 w-fit max-w-full relative min-w-[56px] min-h-[56px]">
                          <Image
                            width={56}
                            height={56}
                            preview={false}
                            className="absolute box-border border-0 m-0 p-0 top-0 min-w-full max-w-full min-h-full max-h-full "
                            alt="chain logo"
                            src={item.img}
                            placeholder={
                              <div className="w-[56px] h-[56px] bg-grey-600 rounded-full"></div>
                            }
                            fallback={imgError}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col justify-center w-full pl-4">
                      <div className="flex items-center">
                        <p className="font-sq font-medium text-lg text-grey-600 dark:text-grey-15 mb-0">
                          {item.name}
                        </p>
                        <div className="flex justify-center items-center ml-[6px] px-[6px] bg-grey-15 dark:bg-grey-550 rounded h-[20px] border-grey-100 dark:border-grey-500 border-solid border">
                          <p className="font-sq text-xs font-normal text-grey-450 dark:text-grey-100 mt-[1px] mb-0">
                            {item.symbol} ≈ $
                            {chainPriceList[item.name] 
                              ? chainPriceList[item.name]?.usd.toFixed(2)
                              : 0}
                          </p>
                        </div>
                      </div>
                      <div className="flex mt-2">
                        <p className="font-sq font-normal text-sm tracking-[0.2px] text-grey-500 dark:text-grey-400 leading-[120%] line-clamp-2 text-left">
                          {item.descrition}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-end lg:min-w-[40px] min-w-[160px]">
                      <div className="flex items-center font-sq font-normal text-grey-500 dark:text-grey-300 text-center">
                        <span className="lg:hidden">APR {item.apy}%</span>
                        <div className="ml-3 mr-[22px] lg:ml-[9px] lg:mr-[13px]">
                          <img src={triple} alt="triple" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </li>
            );
          })}
      </ul>
    </>
  );
}

export default Chains;
