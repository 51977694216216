import httpClient from '../../utils/httpClient';
import { StargateClient } from '@cosmjs/stargate';
import { useEffect, useState } from 'react';
import { Progress, Image } from 'antd';
import { Pie } from '@ant-design/plots';
import WelcomHeader from '../../components/welcomHeader.js';
import 'antd/es/progress/style/css';
import 'antd/es/image/style/css';

function Index() {
  const [chainInfoList, setChainInfoList] = useState([]);
  const [pieConf, setPieConf] = useState({
    height: 200,
    appendPadding: 10,
    data: [],
    angleField: 'total',
    colorField: 'name',
    radius: 1,
    innerRadius: 0.8,
    pieStyle: {
      strokeOpacity: 0,
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: false,
      },
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      const listJson = await httpClient('get', '/list/list.json');
      if (window.keplr) {
        const valueList = await Promise.all(
          listJson.map(async (item) => {
            const chainid = item.name;
            const chainJson = await httpClient(
              'get',
              `/chainInfo/${chainid}.json`
            );
            const client = await StargateClient.connect(chainJson.rpc);
            const chain_id = await client.getChainId(chainJson.id);
            const wallet_info = await window.keplr.getKey(chain_id);
            const balance = await client.getBalance(
              wallet_info.bech32Address,
              `u${chainJson.symbol}`
            );
            const coinCount = parseInt(balance?.amount) / 1e6;
            const balanceStaked = await client.getBalanceStaked(
              wallet_info.bech32Address
            );
            const stakedCount = balanceStaked?.amount / 1e6 || 0;
            return {
              name: chainid,
              value: coinCount,
              stake: stakedCount,
              img: item.img,
              total: coinCount + stakedCount,
            };
          })
        );
        setChainInfoList(valueList);
        const mapValue = valueList.reduce((preVal, nextVal) => {
          preVal.total = (preVal.total || 0) + (nextVal.total || 0)
          return preVal;
        }, {})
        const pieData = valueList.map(item => {
          item.percen = item.total / mapValue.total * 100
          return item
        })
        setPieConf(Object.assign({}, pieConf, {data: pieData}))
        console.log(
          '🚀 ~ file: dashboard.js ~ line 44 ~ pieData ~ pieData',
          pieData
        );
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <WelcomHeader />
      <div className="tap-wrapper w-full pl-16">
        <div className="flex flex-row min-w-[520px]">
          <button className="flex justify-center items-start mr-8 outline-none hover:text-key-blue dark:hover:text-key-skyBlue w-[90px] h-7 text-key-blue border-b-2 border-key-blue dark:text-key-skyBlue dark:border-key-skyBlue">
            <span className="flex font-sq text-base font-medium">Overview</span>
          </button>
        </div>
      </div>
      <div className="flex flex-col px-16 max-w-[1200px]">
        <div className="flex flex-row w-full  gap-[32px] mt-[42px] mb-16">
          <div className="flex flex-col w-full">
            {/* title */}
            <div className="flex flex-row mb-[24.5px] justify-between items-center">
              <div className="flex w-fit">
                <div className="flex w-1 rounded-[1px] bg-key-purple dark:bg-key-blueDm mr-3 dark:shadow-[0px_4px_24px_#0002E9]"></div>
                <h1 className="font-sq font-semibold text-xl text-grey-550 dark:text-grey-50 m-0 ">
                  Asset Ratio by Chain
                </h1>
              </div>
            </div>
            {/* content */}
            <div className="flex flex-col">
              <div className="py-6 pl-6 pr-5 h-[240px] home-tab-default-bg rounded-lg mb-4">
                <div className="flex flex-col overflow-y-scroll h-full list-scroll-y">
                  {chainInfoList.length > 0 &&
                    chainInfoList.map((item) => {
                      return (
                        <div key={item.name} className="flex flex-row items-center justify-between pr-3 mb-[18px]">
                        <div className="flex flex-row w-fit min-w-[160px]">
                          <div className="flex max-w-[18px] max-h-[18px]">
                            <div className="flex w-full relative">
                              <span className="box-border inline-block overflow-hidden bg-none m-0 p-0 relative max-w-full">
                                <Image
                                  alt="chain logo"
                                  src={item.img}
                                  width={18}
                                  height={18}
                                  className="absolute top-0 left-0 z-1"
                                />
                              </span>
                            </div>
                          </div>
                          <h5 className="font-sq text-sm font-medium leading-[1.2] ml-[14.5px] mr-4 whitespace-nowrap text-grey-450 dark:text-grey-200">
                            {item.name}
                          </h5>
                        </div>
                        <Progress
                          percent={parseInt(item.stake / (item.value + item.stake) * 100)}
                          status="active"
                          strokeColor="#8a72ff"
                          trailColor="#0002e9"
                        />
                      </div>
                      )
                    })}
                </div>
              </div>
            </div>
            {/* 标志 */}
            <div className="flex flex-row w-full justify-end items-start">
              <p className="font-sq text-xs leading-[1.2] mr-1 text-grey-500 dark:text-grey-300">
                Available Balance
              </p>
              <div className="flex w-3 h-3 bg-key-blue rounded-[1px] mr-4"></div>
              <p className="font-xs text-xs leading-[1.2] mr-1 text-grey-500 dark:text-grey-300">
                Staked Balance
              </p>
              <div className="flex w-3 h-3 bg-key-lightPurple rounded-[1px]"></div>
            </div>
          </div>

          <div className="flex flex-col w-full">
            {/* title */}
            <div className="flex flex-row mb-[24.5px] justify-between items-center">
              <div className="flex w-fit">
                <div className="flex w-1 rounded-[1px] bg-key-purple dark:bg-key-blueDm mr-3 dark:shadow-[0px_4px_24px_#0002E9]"></div>
                <h1 className="font-sq font-semibold text-xl text-grey-550 dark:text-grey-50 m-0 ">
                  Token Allocation
                </h1>
              </div>
            </div>
            {/* content */}
            <div className="flex flex-col">
              <div className="py-6 pl-6 pr-5 h-[270px] home-tab-default-bg rounded-lg mb-4">
                <div className="flex flex-col overflow-y-scroll h-full list-scroll-y">
                  <div className="flex flex-row items-center justify-between pr-3 mb-[18px]">
                    <div className="flex flex-row  w-full items-center justify-between">
                      {/* <Progress
                        width={200}
                        strokeWidth={9}
                        type="circle"
                        percent={75}
                        strokeColor="#0002e9"
                        trailColor="transparent"
                        strokeLinecap="butt"
                      /> */}
                      <Pie {...pieConf} />
                      {/* <div className="flex">
                        <div className="flex max-w-[18px] max-h-[18px]">
                          <div className="flex w-full relative">
                            <span className="box-border inline-block overflow-hidden bg-none m-0 p-0 relative max-w-full">
                              <span className="box-border inline-block overflow-hidden bg-none m-0 p-0 max-w-full">
                                <img
                                  alt=""
                                  aria-hidden="true"
                                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2756%27%20height=%2756%27/%3e"
                                />
                              </span>
                              <img
                                alt="chain logo"
                                src={umee}
                                decoding="async"
                                data-nimg="intrinsic"
                                className="absolute top-0 left-0 z-1"
                              />
                            </span>
                          </div>
                        </div>
                        <h5 className="font-sq text-sm font-medium leading-[1.2] ml-[14.5px] mr-4 whitespace-nowrap text-grey-450 dark:text-grey-200">
                          Umee
                        </h5>
                      </div>
                      <div className="flex flex-row justify-end w-[122px]">
                        <p className="flex font-sq font-normal text-grey-500 dark:text-grey-300 text-sm mb-0">
                          $236
                        </p>
                        <p className="font-sq font-normal text-sm text-grey-400 dark:text-grey-450 ml-1 mb-0">
                          (99.6%)
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
