// import home from './image/home.svg';
// import chains from './image/chains.svg';
// import logo from './image/logo.png';
import { BrowserRouter } from 'react-router-dom';
// import Chains from './page/chains/index';
// import Home from './page/home/index';
import Routes from './routers';
import { StoreProvider } from './store/store';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <StoreProvider>
        <Routes />
      </StoreProvider>
    </BrowserRouter>
  );
}

export default App;
