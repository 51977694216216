import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { StargateClient } from '@cosmjs/stargate';
import copy from '../image/copy.svg';
import { useStateStore, useDispatchStore } from '../store/store';
import httpClient from '../utils/httpClient';

export default function WalletAddress() {
  // settimeout 是因为插件注入API需要时间，直接检测会一直报错
  const { chainid } = useParams();
  const { wallet } = useStateStore();
  const dispatch = useDispatchStore();
  useEffect(() => {
    const fetchData = () => {
      setTimeout(async () => {
        if (!window.keplr) {
          alert('Please install keplr extension');
        } else {
          const chainJson = await httpClient(
            'get',
            `/chainInfo/${chainid}.json`
          );
          const client = await StargateClient.connect(chainJson.rpc);
          const chain_id = await client.getChainId(chainJson.id)
          const wallet_info = await window.keplr.getKey(chain_id);
          await window.keplr.enable(chain_id);
          dispatch({
            type: 'setWallet',
            payload: {
              ...wallet_info,
              addressStr: `${wallet_info.bech32Address.substring(
                0,
                8
              )}...${wallet_info.bech32Address.substring(
                wallet_info.bech32Address.length - 4
              )}`,
            },
          });
          
          window.addEventListener('keplr_keystorechange', () => {
            console.log(
              'Key store in Keplr is changed. You may need to refetch the account info.'
            );
            window.location.reload();
          });
        }
      }, 100);
    }
    fetchData();
    return () => {
      dispatch({
        type: 'init'
      })
    }
  }, [chainid, dispatch])
  return (
    <div className="w-full flex flex-col px-16 md:max-w-full max-w-[1200px]">
      <div className="flex w-full justify-end pb-10">
        <div className="flex flex-row items-center rounded hover:bg-grey-50 dark:hover:bg-grey-600 tooltip px-2 py-1.5 bg-grey-15 dark:bg-grey-500/[0.3]">
          <span className="font-sq text-xs text-grey-450 dark:text-grey-300 mr-2">
            {wallet.addressStr}
          </span>
          <div className="w-3 h-3 relative">
            <img src={copy} alt="copy" />
          </div>
        </div>
      </div>
    </div>
  );
}
