import logo from '../../image/logo.png';
import telegram from '../../image/telegram-plane.svg';
import twitter from '../../image/twitter.svg';
import discord from '../../image/discord.svg';
import youtube from '../../image/youtube.svg';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import httpClient from '../../utils/httpClient';

function Index() {
  const [chainList, setList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const listJson = await httpClient('get', `/list/list.json`);
      setList(listJson);
    };
    fetchData();
  }, []);
  return (
    <div className="relative w-full bg-[#13182c] min-h-screen text-white font-mono">
      {/* navigator */}
      <nav
        className="bg-grey-600/[0.3]"
        style={{ borderBottomWidth: 1, borderColor: 'rgba(110,129,203,.25)' }}
      >
        {/* logo */}
        <div className="flex flex-row items-center justify-between mx-auto max-w-[1200px] min-w-[1200px] py-5">
          <div className="flex items-center justify-center ">
            <img
              className="max-w-[50px] max-h-[50px] mr-1"
              src={logo}
              alt="logo"
            />
            <div className="pl-2 text-3xl font-bold inline-block">Pelican</div>
          </div>
          {/* menu */}
          <div>
            <Link
              className="rounded-full bg-key-purple px-6 py-3 text-white font-bold"
              to="/dashboard"
            >
              Go to App
            </Link>
          </div>
        </div>
      </nav>
      {/* content */}
      <section className="flex flex-col items-center">
        {/* banner */}
        <div className="flex flex-col max-w-[1200px] min-w-[1200px] text-center">
          <div className="pt-28">
            <h1 className="text-6xl text-white leading-snug">
              Unlocking Liquidity for
              <span className="block">Staked Assets</span>
            </h1>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-lg">
              Stake your PoS assets while earning DeFi yields
            </p>
            <Link
              className="block rounded-lg w-[200px] bg-red-400 px-6 py-3 text-white font-bold"
              to="/dashboard"
            >
              Go to Start
            </Link>
          </div>
        </div>
      </section>
      {/* network */}
      <section className="flex flex-col items-center py-15 pt-32">
        <div className="flex flex-col max-w-[1200px] min-w-[1200px] text-center">
          <h1 className="text-6xl text-white">Supported Networks</h1>
          <ul className="flex flex-wrap">
            {chainList.length > 0 && chainList.map((item) => {
              return (
                <li key={item.name} className="flex flex-col rounded-xl w-[350px] mr-10 mt-10 px-10 py-5 bg-[#161c32] hover:bg-gradient-to-r from-[#5c4243] to-[#2f3148]  items-center">
                  <img
                    className="block w-[40px] h-[40px] mb-5"
                    src={item.img}
                    alt="chain"
                  />
                  <h1 className="text-white font-bold text-2xl">{item.name}</h1>
                  <div>
                    <span className="text-green-600 text-xl font-bold">
                      ~{item.apy}%
                    </span>
                    <span className="text-gray-400 font-bold">APY</span>
                  </div>
                  <a
                    href={`/dashboard/chains/${item.name}`}
                    className="block text-white w-full rounded-2xl font-bold bg-gray-600 mt-5 py-3 hover:bg-[#13182c]"
                  >
                    start staking
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
      {/* featture */}
      <section className="flex flex-col items-center my-20 p-10 bg-[#161c32]">
        <h1 className="text-6xl text-white py-14">Features</h1>
        <div className="flex flex-row max-w-[1200px] min-w-[1200px] pb-20 flex-wrap">
          <div className="bg-[#13182c] opacity-90 px-10 py-8 mr-10 w-[350px]">
            <h1 className="text-white font-bold">Unlock Liquidity</h1>
            <p className="text-gray-400">
              Stake and receive stkASSETs to maintain liquidity of otherwise
              locked assets
            </p>
          </div>
          <div className="bg-[#13182c] opacity-90 px-10 py-8 mr-10 w-[350px]">
            <h1 className="text-white font-bold">Leverage Defi</h1>
            <p className="text-gray-400">
              Explore DeFi opportunities using the liquid stkASSETs while
              earning staking rewards
            </p>
          </div>
          <div className="bg-[#13182c] opacity-90 px-10 py-8 mr-10 w-[350px]">
            <h1 className="text-white font-bold">Instant Redemption</h1>
            <p className="text-gray-400">
              Skip unstaking and unbonding process by directly swapping
              stkASSETs for native assets
            </p>
          </div>
        </div>
      </section>
      {/* footer */}
      <section className="flex flex-col items-center py-15">
        <h1 className="text-6xl text-white">Join Our Community</h1>
        {/* media */}
        <div className="max-w-[1200px] min-w-[1200px]">
          <ul className="flex flex-row justify-center">
            <li className="flex px-5 py-5">
              <a
                href="/"
                className="flex bg-[#1c233f] rounded-2xl py-6 px-10 w-[410px] hover:opacity-80 overflow-hidden"
              >
                <div className="rounded-full bg-[#3b4051] w-[46px] h-[46px] flex items-center justify-center mr-4">
                  <img className="w-[25px] h-[25px]" src={telegram} alt="tel" />
                </div>
                <div>
                  <h1 className="text-white mb-0 font-bold">Telegrame</h1>
                  <h2 className="text-gray-500">Chat with the community</h2>
                </div>
              </a>
            </li>
            <li className="flex px-5 py-5">
              <a
                href="/"
                className="flex bg-[#1c233f] rounded-2xl py-6 px-10 w-[410px] hover:opacity-80 overflow-hidden"
              >
                <div className="rounded-full bg-[#3b4051] w-[46px] h-[46px] flex items-center justify-center mr-4">
                  <img className="w-[25px] h-[25px]" src={twitter} alt="tel" />
                </div>
                <div>
                  <h1 className="text-white mb-0 font-bold">Twitter</h1>
                  <h2 className="text-gray-500">stay upload</h2>
                </div>
              </a>
            </li>
          </ul>
          <ul className="flex flex-row justify-center">
            <li className="flex px-5 py-5">
              <a
                href="/"
                className="flex bg-[#1c233f] rounded-2xl py-6 px-10 w-[410px] hover:opacity-80 overflow-hidden"
              >
                <div className="rounded-full bg-[#3b4051] w-[46px] h-[46px] flex items-center justify-center mr-4">
                  <img className="w-[25px] h-[25px]" src={discord} alt="tel" />
                </div>
                <div>
                  <h1 className="text-white mb-0 font-bold">Discord</h1>
                  <h2 className="text-gray-500">Chat with the community</h2>
                </div>
              </a>
            </li>
            <li className="flex px-5 py-5">
              <a
                href="/"
                className="flex bg-[#1c233f] rounded-2xl py-6 px-10 w-[410px] hover:opacity-80 overflow-hidden"
              >
                <div className="rounded-full bg-[#3b4051] w-[46px] h-[46px] flex items-center justify-center mr-4">
                  <img className="w-[25px] h-[25px]" src={youtube} alt="tel" />
                </div>
                <div>
                  <h1 className="text-white mb-0 font-bold">Youtube</h1>
                  <h2 className="text-gray-500">Learn about pelican</h2>
                </div>
              </a>
            </li>
          </ul>
        </div>
        {/* privacy */}
        <div className="max-w-[1200px] min-w-[1200px] flex justify-around p-6">
          <div>Privacy Policy</div>
          <div>2022 | Built with by Pelican</div>
          <div>Terms of Service</div>
        </div>
      </section>
    </div>
  );
}

export default Index;
