import { useState, useEffect } from 'react';
import { Modal, Button, InputNumber, notification, Image } from 'antd';
import { useParams } from 'react-router-dom';
import { CloseOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import {
  StargateClient,
  setupDistributionExtension,
  SigningStargateClient,
  isDeliverTxSuccess,
  calculateFee,
} from '@cosmjs/stargate';
import { useDispatchStore } from '../../store/store';
import httpClient from '../../utils/httpClient';
import WalletAddress from '../../components/wallletAddress';
import { useStateStore } from '../../store/store';
import 'antd/es/modal/style/css';
import 'antd/es/space/style/css';
import 'antd/es/button/style/css';
import 'antd/es/input-number/style/css';
import 'antd/es/notification/style/css';
import 'antd/es/image/style/css';

function Index() {
  const { chainid } = useParams();
  const dispatch = useDispatchStore();
  const {
    wallet,
    rewards,
    totalStaked,
    availableBalance,
    totalBalance,
    chainInfo,
    currentPrice,
    validatorInfo,
  } = useStateStore();
  const [currentClient, setCurrentClient] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDelegator, setIsDelegator] = useState(false);
  const [delegaNum, setDelegaNum] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      if (window.keplr && wallet.bech32Address) {
        const chainJson = await httpClient('get', `/chainInfo/${chainid}.json`);
        dispatch({
          type: 'setChain',
          payload: chainJson,
        });
        const client = await StargateClient.connect(chainJson.rpc);
        setCurrentClient(client);
        const coinPrice = await httpClient(
          'get',
          `https://api.coingecko.com/api/v3/simple/price?ids=${chainJson.id}&vs_currencies=usd`
        );
        // const rpcUrl = 'https://rpc.sentry-02.theta-testnet.polypore.xyz/';
        const queryClient = await client.forceGetQueryClient();
        const distribution = await setupDistributionExtension(queryClient);
        // 获取当前的收益
        console.log(
          '🚀 ~ file: chainsDetail.js ~ line 43 ~ fetchData ~ wallet.bech32Address',
          wallet.bech32Address
        );
        const currentRewards =
          await distribution.distribution.delegationTotalRewards(
            wallet.bech32Address
          );
        const calRewards =
          currentRewards.total.length > 0
            ? parseFloat(parseInt(currentRewards.total[0].amount) / 1e24)
            : 0;
        dispatch({
          type: 'setRewards',
          payload: calRewards,
        });
        // 获取当前币的USD价格
        dispatch({
          type: 'setPrice',
          payload: coinPrice[chainJson.id]['usd'] || 0,
        });

        // // 获取抵押节点的信息
        const validatorInfo = await queryClient.staking.validator(
          chainJson.validator_address
        );
        console.log(
          '🚀 ~ file: chainsDetail.js ~ line 80 ~ fetchData ~ validatorInfo',
          validatorInfo
        );
        dispatch({
          type: 'setValidatorInfo',
          payload: validatorInfo,
        });
        // 获取所有余额
        const allBalaces = await client.getAllBalances(wallet.bech32Address);
        console.log(
          '🚀 ~ file: chainsDetail.js ~ line 62 ~ fetchData ~ allBalaces',
          allBalaces
        );
        const calAllBalances =
          allBalaces.length > 0
            ? parseFloat(parseInt(allBalaces[0].amount) / 1e6)
            : 0;
        dispatch({
          type: 'setAvailableBalance',
          payload: calAllBalances,
        });
        // 获取当前节点的抵押
        const delegation = wallet.bech32Address
        ? (await client.getBalanceStaked(wallet.bech32Address)) || 0
        : 0;
        console.log("🚀 ~ file: chainsDetail.js ~ line 122 ~ fetchData ~ delegation", delegation)
        const calTotalStaked = delegation
          ? parseFloat(parseInt(delegation.amount) / 1e6)
          : 0;
        dispatch({
          type: 'setTotalStaked',
          payload: calTotalStaked,
        });
        const calTotalBalance = parseFloat(
          calAllBalances + calRewards + calTotalStaked
        );
        dispatch({
          type: 'setTotalBalance',
          payload: calTotalBalance,
        });
      }
    };
    fetchData();
  }, [chainid, dispatch, wallet.bech32Address]);

  const refreshToken = async () => {
    if (window.keplr && wallet.bech32Address) {
      const coinPrice = await httpClient(
        'get',
        `https://api.coingecko.com/api/v3/simple/price?ids=${chainInfo.id}&vs_currencies=usd`
      );
      const queryClient = await currentClient.forceGetQueryClient();
      const distribution = await setupDistributionExtension(queryClient);
      // 获取当前的收益
      const currentRewards =
        await distribution.distribution.delegationTotalRewards(
          wallet.bech32Address
        );
      const calRewards =
        currentRewards.total.length > 0
          ? parseFloat(parseInt(currentRewards.total[0].amount) / 1e24)
          : 0;
      dispatch({
        type: 'setRewards',
        payload: calRewards,
      });
      // 获取当前币的USD价格
      dispatch({
        type: 'setPrice',
        payload: coinPrice[chainInfo.id]['usd'],
      });

      // 获取所有余额
      const allBalaces = await currentClient.getAllBalances(
        wallet.bech32Address
      );
      console.log(
        '🚀 ~ file: chainsDetail.js ~ line 62 ~ fetchData ~ allBalaces',
        allBalaces
      );
      const calAllBalances =
        allBalaces.length > 0
          ? parseFloat(parseInt(allBalaces[0].amount) / 1e6)
          : 0;
      dispatch({
        type: 'setAvailableBalance',
        payload: calAllBalances,
      });
      // 获取当前节点的抵押
      const delegation = await currentClient.getDelegation(
        wallet.bech32Address,
        chainInfo.validator_address
      );
      const calTotalStaked = delegation
        ? parseFloat(parseInt(delegation.amount) / 1e6)
        : 0;
      dispatch({
        type: 'setTotalStaked',
        payload: calTotalStaked,
      });
      const calTotalBalance = parseFloat(
        calAllBalances + calRewards + calTotalStaked
      );
      dispatch({
        type: 'setTotalBalance',
        payload: calTotalBalance,
      });
    }
  };

  const getRewards = async () => {
    showTips('claim', 'Caliming...')
    const fee = calculateFee(250000, `0.0005u${chainInfo.symbol}`);
    // chainid 要和链的 chainid 一致
    // const client = await StargateClient.connect(chainInfo.rpc);
    const chainid = await currentClient.getChainId();
    const offlineSigner = window.keplr.getOfflineSigner(chainid);
    const sighClient = await SigningStargateClient.connectWithSigner(
      chainInfo.rpc,
      offlineSigner
    );
    try {
      const resp = await sighClient.withdrawRewards(
        wallet.bech32Address,
        chainInfo.validator_address,
        fee,
        ''
      );
      const result = await isDeliverTxSuccess(resp);
      if (result) {
        showTips('claim', `Claim success!The transaction hash:${resp.transactionHash}`, 4.5)
        console.log(result);
        await refreshToken();
      } else {
        console.log(resp.rawLog);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showDelegation = () => {
    setIsDelegator(true);
  };

  const showTips = (key, description, duration) => {
    const args = {
      key,
      message: 'Notification',
      description,
      duration: duration || 0,
    };
    notification.open(args);
  }

  const stake = async () => {
    showTips('stake', 'staking...')
    const fee = calculateFee(250000, `0.0005u${chainInfo.symbol}`);
    if (delegaNum > 0) {
      // chainid 要和链的 chainid 一致
      // const client = await StargateClient.connect(chainInfo.rpc);
      const chainid = await currentClient.getChainId();
      const offlineSigner = window.keplr.getOfflineSigner(chainid);
      const sighClient = await SigningStargateClient.connectWithSigner(
        chainInfo.rpc,
        offlineSigner
      );
      const resp = await sighClient.delegateTokens(
        wallet.bech32Address,
        chainInfo.validator_address,
        { denom: `u${chainInfo.symbol}`, amount: (delegaNum * 1e6).toString() },
        fee,
        ''
      );
      const result = await isDeliverTxSuccess(resp);
      if (result) {
        console.log(resp.transactionHash);
        showTips('stake', `staked!The transaction hash:${resp.transactionHash}`, 4.5)
        await refreshToken();
      } else {
        console.log(resp.rawLog);
      }
    }
  };

  const unStake = async () => {
    showTips('unstake', 'unstaking...')
    const fee = calculateFee(250000, `0.0005u${chainInfo.symbol}`);
    // chainid 要和链的 chainid 一致
    // const client = await StargateClient.connect(chainInfo.rpc);
    const chainid = await currentClient.getChainId();
    const offlineSigner = window.keplr.getOfflineSigner(chainid);
    const sighClient = await SigningStargateClient.connectWithSigner(
      chainInfo.rpc,
      offlineSigner
    );
    const resp = await sighClient.undelegateTokens(
      wallet.bech32Address,
      chainInfo.validator_address,
      { denom: `u${chainInfo.symbol}`, amount: (totalStaked * 1e6).toString() },
      fee,
      ''
    );
    const result = await isDeliverTxSuccess(resp);
    if (result) {
      showTips('unstake', `unStaked!The transaction hash:${resp.transactionHash}`, 4.5)
      console.log(result);
      await refreshToken();
    } else {
      console.log(resp.rawLog);
    }
    // try {
    // } catch (error) {
    //   console.log(error)
    // }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        className="rounded-xl dark"
        width={629}
        bodyStyle={{
          padding: 0,
        }}
        centered
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        closable={false}
        afterClose={() => {
          setDelegaNum(0);
          setIsDelegator(false);
        }}
      >
        <div className="bg-grey-750 flex flex-col w-full h-fit font-sq pl-5 pr-2.5 pb-5">
          <div>
            {/* close icon */}
            <div className="flex mb-5 pt-5">
              {!isDelegator && (
                <div
                  className="text-grey-500 cursor-pointer text-lg"
                  onClick={handleCancel}
                >
                  <CloseOutlined />
                </div>
              )}
              {isDelegator && (
                <div
                  className="text-grey-500 cursor-pointer text-lg"
                  onClick={() => {
                    setIsDelegator(false);
                  }}
                >
                  <ArrowLeftOutlined />
                </div>
              )}
            </div>
            {/* validator */}
            <div className="flex flex-col w-full h-full pr-2.5 overflow-y-auto overflow-x-hidden list-scroll-y">
              <div className="w-[654px] md:w-full">
                <div className="flex mb-4">
                  <div className="w-14 h-14 shrink-0">
                    <div className="w-full h-full shrink-0 overflow-hidden rounded-full bg-[linear-gradient(180deg,_#C9D5FF_0%,_#F3F7FF_88.62%)]">
                      <span className="box-border inline-block overflow-hidden bg-none m-0 p-0 relative max-w-full">
                        <Image
                          alt="chain logo"
                          src={chainInfo?.image?.large}
                          placeholder={<div className='w-[56px] h-[56px] rounded-full bg-grey-600'></div>}
                          className="absolute top-0 left-0 z-1"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="ml-4">
                    {/* validator icon */}
                    <div className="flex items-center">
                      <h4 className="mr-2 text-lg leading-none font-semibold  dark:text-grey-100 text-white mb-0">
                        {chainInfo.name}
                      </h4>
                      <a
                        target="_blank"
                        href={
                          chainInfo.links ? chainInfo.links.homepage[0] : '/'
                        }
                        className="flex flex-row w-fit h-fit items-center gap-1 p-[6px] dark:bg-grey-500/[0.5] rounded-sm"
                        rel="noreferrer"
                      >
                        <span className="text-[12px] text-grey-550 dark:text-grey-200 leading-[1] mt-[1px]">
                          Website
                        </span>
                      </a>
                    </div>
                    <div className="mt-3 flex items-center text-sm leading-none text-grey-450 dark:text-grey-300 font-medium">
                      <span>
                        Commission{' '}
                        {validatorInfo?.validator?.commission?.commissionRates
                          ?.rate / 1e16}
                        %
                      </span>
                      <span className="mx-2">|</span>
                      {/* <span>APR 38.13%</span> */}
                    </div>
                  </div>
                </div>
                {/* <p className="mt-4 mb-5 leading-[1.4] text-sm tracking-[0.2px] font-normal text-grey-500 dark:text-grey-400">
                Experienced enterprise validator running Proof-of-Stake nodes
                for the Cosmos ecosystem and beyond. Visit our website for more
                information: https://stakin.com
              </p> */}
                <div className="flex w-full">
                  <div className="w-full mr-4 my-2 py-10 md:py-5 px-8 md:px-5 border home-tab-default-bg border-grey-100 dark:border-grey-750 rounded-xl">
                    <div className="text-base  text-grey-450 dark:text-grey-400">
                      Your Delegation
                    </div>
                    <div className="flex items-end mt-3 font-bold text-grey-550 dark:text-grey-100">
                      <span className="text-xl leading-none">
                        {totalStaked}
                      </span>
                      <span className="text-xs ml-1 font-bold">
                        {chainInfo?.symbol?.toUpperCase()}
                      </span>
                    </div>
                    <div className="mt-1.5 font-semibold text-sm text-grey-450 dark:text-grey-400">
                      = ${(totalStaked * currentPrice).toFixed(2)}
                    </div>
                  </div>
                  <div className="w-full my-2 py-10 md:py-5 px-8 md:px-5 border home-tab-default-bg border-grey-100 dark:border-grey-750 rounded-xl">
                    <div className="text-base  text-grey-450 dark:text-grey-400">
                      Available to Delegate
                    </div>
                    <div className="flex items-end mt-3 font-bold text-grey-550 dark:text-grey-100">
                      <span className="text-xl leading-none">
                        {availableBalance}
                      </span>
                      <span className="text-xs ml-1 font-bold">
                        {chainInfo?.symbol?.toUpperCase()}
                      </span>
                    </div>
                    <div className="mt-1.5 font-semibold text-sm text-grey-450 dark:text-grey-400">
                      = ${(availableBalance * currentPrice).toFixed(2)}
                    </div>
                  </div>
                </div>
                {isDelegator && (
                  <div className="w-full my-2 py-10 md:py-5 px-8 md:px-5 border home-tab-default-bg border-grey-100 dark:border-grey-750 rounded-xl">
                    <div className="text-base  text-grey-450 dark:text-grey-400">
                      Amount to Delegate
                    </div>
                    <div className="flex mt-3 font-bold text-grey-550 dark:text-grey-100 border rounded border-grey-450 py-1 px-1 items-center">
                      <InputNumber
                        style={{ width: '100%', color: '#fff' }}
                        controls={false}
                        bordered={false}
                        keyboard={false}
                        min={0}
                        max={availableBalance}
                        value={delegaNum}
                        onChange={setDelegaNum}
                      />
                      <Button
                        type="link"
                        onClick={() => {
                          setDelegaNum(availableBalance);
                        }}
                      >
                        MAX
                      </Button>
                      <span className="text-xs ml-1 font-bold">
                        {chainInfo?.symbol?.toUpperCase()}
                      </span>
                    </div>
                    <div className="mt-1.5 font-semibold text-sm text-grey-450 dark:text-grey-400">
                      = ${(delegaNum * currentPrice).toFixed(2)}
                    </div>
                  </div>
                )}
                <div className="mt-5 flex gap-2 items-center justify-end">
                  {!isDelegator && (
                    <>
                      <button
                        className="bg-grey-30 dark:bg-grey-500/25 rounded-lg py-3.5 px-3 md:px-2.5 font-semibold text-grey-500 dark:text-grey-100 md:text-sm"
                        onClick={unStake}
                      >
                        Undelegate
                      </button>
                      <button
                        className="bg-key-blue dark:bg-key-bluePurple rounded-lg py-3.5 px-5 md:px-3 font-semibold text-grey-30 md:text-sm"
                        onClick={showDelegation}
                      >
                        Delegate
                      </button>
                    </>
                  )}
                  {isDelegator && (
                    <button
                      className="bg-key-blue dark:bg-key-bluePurple rounded-lg py-3.5 px-5 md:px-3 font-semibold text-grey-30 md:text-sm"
                      onClick={stake}
                    >
                      Delegate
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <WalletAddress />
      <div className="flex flex-col px-16 max-w-[1200px] pt-10 mb-[56px]">
        <div className="flex flex-row items-start gap-5">
          {/* left */}
          <div className="flex w-full flex-col flex-1 h-full">
            <div className="flex w-full justify-between">
              <div className="flex flex-row mb-[24.5px] justify-between items-center">
                <div className="flex w-fit">
                  <div className="flex w-1 rounded-[1px] bg-key-purple dark:bg-key-blueDm mr-3 dark:shadow-[0px_4px_24px_#0002E9]"></div>
                  <h1 className="font-sq font-semibold text-xl text-grey-550 dark:text-grey-50 m-0 ">
                    Asset Ratio by Chain
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex flex-col h-full p-6 bg-grey-30 dark:bg-grey-500/[0.12] rounded-xl text-left">
              <div className="flex gap-4 mb-4">
                {/* icon */}
                <div className="flex w-14 h-14 shrink-0">
                  <div className="flex w-full relative">
                    <Image src={chainInfo?.image?.large} alt="" placeholder={<div className='w-[56px] h-[56px] rounded-full bg-grey-600'></div>} />
                  </div>
                </div>
                {/* text */}
                <div className="flex flex-row items-center gap-2">
                  <h5 className="text-[22px] text-grey-600 dark:text-grey-15 font-semibold leading-[1]">
                    {chainInfo.name}
                  </h5>
                  <p className="flex px-[6px] py-1 bg-grey-15 dark:bg-grey-550 text-grey-450 dark:text-grey-100 text-xs leading-[1] border-[1px] border-grey-100 dark:border-grey-500 rounded-[4px]">
                    {chainInfo?.symbol?.toUpperCase()} ≈ ${currentPrice.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-1 mb-3">
                <a
                  target="_blank"
                  href={chainInfo.links ? chainInfo.links.homepage[0] : '/'}
                  className="flex flex-row w-fit h-fit items-center gap-1 p-[6px] bg-grey-white dark:bg-grey-500/[0.5] rounded-sm"
                  rel="noreferrer"
                >
                  <span className="text-[12px] text-grey-550 dark:text-grey-200 leading-[1] mt-[1px]">
                    Website
                  </span>
                </a>
                <a
                  target="_blank"
                  href={
                    chainInfo.links ? chainInfo.links.blockchain_site[0] : '/'
                  }
                  className="flex flex-row w-fit h-fit items-center gap-1 p-[6px] bg-grey-white dark:bg-grey-500/[0.5] rounded-sm"
                  rel="noreferrer"
                >
                  <span className="text-[12px] text-grey-550 dark:text-grey-200 leading-[1] mt-[1px]">
                    Explorer
                  </span>
                </a>
              </div>
              <p className="text-sm tracking-[0.2px] text-grey-500 dark:text-grey-400 leading-[1.6] font-normal">
                {chainInfo.description ? chainInfo.description.en : ''}
              </p>
            </div>
          </div>
          {/* right */}
          <div className="flex flex-col flex-1 w-full h-full">
            {/* title */}
            <div className="flex w-full justify-between">
              <div className="flex flex-row mb-[24.5px] justify-between items-center">
                <div className="flex w-fit">
                  <div className="flex w-1 rounded-[1px] bg-key-purple dark:bg-key-blueDm mr-3 dark:shadow-[0px_4px_24px_#0002E9]"></div>
                  <h1 className="font-sq font-semibold text-xl sm:text-base text-grey-550 dark:text-grey-400">
                    <strong className="text-key-blue dark:text-grey-15 font-semibold">
                      {wallet.name}
                    </strong>
                    's {chainInfo.name} Balance
                  </h1>
                </div>
              </div>
            </div>
            {/* chain info */}
            <div className="grid grid-cols-2 gap-3">
              {/* balance */}
              <div className="flex flex-col w-full px-8 py-8 rounded-xl bg-grey-white dark:bg-grey-500/[0.12] hover:dark:bg-grey-500/[0.2] border-[1px] border-grey-100 dark:border-grey-750 text-left">
                <h5 className="text-sm text-grey-450 leading-[1] mb-3">
                  Total Balance
                </h5>
                <div className="flex flex-row items-center gap-[6px] mb-[6px]">
                  <p className="text-2xl font-semibold text-grey-550 dark:text-grey-100 leading-[1] mb-0">
                    {totalBalance.toFixed(2)}
                    <span className="text-sm text-grey-550 dark:text-grey-100 leading-[1] ml-1">
                      {chainInfo?.symbol?.toUpperCase()}
                    </span>
                  </p>
                </div>
                <p className="text-sm text-grey-450 dark:text-grey-200">
                  ≈ ${(totalBalance * currentPrice).toFixed(2)}
                </p>
              </div>
              {/* stake */}
              <div className="flex flex-col w-full px-8 py-8 rounded-xl bg-grey-white dark:bg-grey-500/[0.12] hover:dark:bg-grey-500/[0.2] border-[1px] border-grey-100 dark:border-grey-750 text-left">
                <h5 className="text-sm text-grey-450 leading-[1] mb-3">
                  Total Staked
                </h5>
                <div className="flex flex-row items-center gap-[6px] mb-[6px]">
                  <p className="text-2xl font-semibold text-grey-550 dark:text-grey-100 leading-[1] mb-0">
                    {totalStaked.toFixed(2)}
                    <span className="text-sm text-grey-550 dark:text-grey-100 leading-[1] ml-1">
                      {chainInfo?.symbol?.toUpperCase()}
                    </span>
                  </p>
                </div>
                <p className="text-sm text-grey-450 dark:text-grey-200">
                  ≈ ${(totalStaked * currentPrice).toFixed(2)}
                </p>
              </div>
              {/* available balacne */}
              <div className="flex flex-col w-full px-8 py-8 rounded-xl bg-grey-white dark:bg-grey-500/[0.12] hover:dark:bg-grey-500/[0.2] border-[1px] border-grey-100 dark:border-grey-750 text-left">
                <h5 className="text-sm text-grey-450 leading-[1] mb-3">
                  Available Balance
                </h5>
                <div className="flex flex-row items-center gap-[6px] mb-[6px]">
                  <p className="text-2xl font-semibold text-grey-550 dark:text-grey-100 leading-[1] mb-0">
                    {availableBalance.toFixed(2)}
                    <span className="text-sm text-grey-550 dark:text-grey-100 leading-[1] ml-1">
                      {chainInfo?.symbol?.toUpperCase()}
                    </span>
                  </p>
                </div>
                <p className="text-sm text-grey-450 dark:text-grey-200 mb-0">
                  &lt; ${(availableBalance * currentPrice).toFixed(2)}
                </p>
                <button
                  className="flex w-fit items-center px-2 py-[6px] mt-3 bg-grey-white dark:bg-grey-500/50 border-[1px] border-grey-450 dark:border-transparent rounded-[4px] text-xs text-grey-500 dark:text-grey-200 leading-[1] hover:bg-grey-50 dark:hover:bg-grey-600"
                  onClick={showModal}
                >
                  Stake
                  {/* <span className='text-xs text-grey-500 dark:text-grey-50 rotate-90 ml-1 leading-[1]'>-&gt;</span> */}
                </button>
              </div>
              {/* claimable rewards */}
              <div className="flex flex-col w-full px-8 py-8 rounded-xl bg-grey-white dark:bg-grey-500/[0.12] hover:dark:bg-grey-500/[0.2] border-[1px] border-grey-100 dark:border-grey-750 text-left">
                <h5 className="text-sm text-grey-450 leading-[1] mb-3">
                  Claimable Rewards
                </h5>
                <div className="flex flex-row items-center gap-[6px] mb-[6px]">
                  <p className="text-2xl font-semibold text-grey-550 dark:text-grey-100 leading-[1] mb-0">
                    {rewards.toFixed(2)}
                    <span className="text-sm text-grey-550 dark:text-grey-100 leading-[1] ml-1">
                      {chainInfo?.symbol?.toUpperCase()}
                    </span>
                  </p>
                </div>
                <p className="text-sm text-grey-450 dark:text-grey-200 mb-0">
                  &lt; ${(rewards * currentPrice).toFixed(2)}
                </p>
                <button
                  className="flex justify-center items-center w-[100px] h-6 mt-3 bg-key-bluePurple rounded-[4px] hover:bg-key-blue text-white font-bold"
                  onClick={getRewards}
                >
                  Claim All
                  {/* <span className='text-xs text-grey-500 dark:text-grey-50 rotate-90 ml-1 leading-[1]'>-&gt;</span> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
