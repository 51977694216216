import httpClient from '../utils/httpClient';
import { StargateClient, setupDistributionExtension } from '@cosmjs/stargate';
import { useEffect, useState } from 'react'

export default function WelcomHeader() {
  const [totalValue, setTotalValue] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const listJson = await httpClient('get', '/list/list.json')
      if (window.keplr) {
        const valueList = await Promise.all(listJson.map(async item => {
          const chainid = item.name;
          const chainJson = await httpClient(
            'get',
            `/chainInfo/${chainid}.json`
          );
          const client = await StargateClient.connect(chainJson.rpc);
          const chain_id = await client.getChainId(chainJson.id)
          const wallet_info = await window.keplr.getKey(chain_id);
          const balance = await client.getBalance(wallet_info.bech32Address, `u${chainJson.symbol}`)
          const priceObj = await httpClient('get', `https://api.coingecko.com/api/v3/simple/price?ids=${chainJson.id}&vs_currencies=usd`)
          const coinCount = parseInt(balance?.amount) / 1e6
          const price = priceObj[chainid] ? priceObj[chainid].usd : 0
          const value = coinCount * price ;
          const balanceStaked = await client.getBalanceStaked(wallet_info.bech32Address)
          const stakedCount = balanceStaked?.amount / 1e6 || 0
          const stakedVal = stakedCount * price
          const queryClient = await client.forceGetQueryClient();
          const distribution = await setupDistributionExtension(queryClient);
          const currentRewards =
          await distribution.distribution.delegationTotalRewards(
            wallet_info.bech32Address
          );
          const rewardCount =
          currentRewards.total.length > 0
            ? parseFloat(parseInt(currentRewards.total[0].amount) / 1e24)
            : 0;
          const reward = rewardCount * price
          return {value: value + stakedVal, reward}
        }))
        const mapValue = valueList.reduce((preVal, nextVal) => {
          preVal.value = (preVal.value || 0) + (nextVal.value || 0)
          preVal.reward = (preVal.reward || 0) + (nextVal.reward || 0)
          return preVal;
        }, {})
        setTotalValue(mapValue.value.toFixed(2))
        setTotalRewards(mapValue.reward.toFixed(2))
      }
    }
    fetchData();
  })

  return (
    <div className="w-full flex flex-col px-16 md:max-w-full max-w-[1200px]">
      <div className="flex">
        <div className="flex w-full mb-10">
          <div className="flex flex-col w-full">
            <div className="flex flex-row justify-between">
              <div className="flex flex-col items-start select-none">
                <h1 className="flex flex-col font-sq font-bold mt-7 text-grey-600 dark:text-grey-400 text-[28px] min-w-[300px] leading-[1.2] whitespace-pre-wrap text-left">
                  <span>Welcome to the pelican!</span>
                </h1>
              </div>
              <div className="flex flex-row justify-end w-full text-left">
                <div className="flex flex-col justify-center px-10 w-full h-[152px] bg-key-blue rounded-xl max-w-[285.5px] mr-4">
                  <h5 className="font-sq font-medium text-[14px] text-grey-200 leading-none mb-[14px] select-none">
                    Total Asset Value
                  </h5>
                  <p className="font-sq font-bold text-[28px] text-ƒ leading-none text-white">
                    ${totalValue}
                  </p>
                </div>
                <div className="flex flex-col justify-center px-10 w-full h-[152px] bg-key-blue rounded-xl max-w-[285.5px]">
                  <h5 className="font-sq font-medium text-[14px] text-grey-200 leading-none mb-[14px] select-none">
                    Total Claimable Rewards
                  </h5>
                  <div className="flex flex-row items-center gap-3">
                    <p className="font-sq font-bold text-[28px] text-white leading-none">
                      ${totalRewards}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
