import { useRoutes } from 'react-router-dom';
import Index from './page/index';
import Home from './page/home/home';
import Dashboard from './page/dashboard/dashboard';
import Chains from './page/chains/chains';
import ChainsDetail from './page/chainsDetail/chainsDetail';

const Routes = () => {
  const routes = useRoutes([
    { path: '/', element: <Index /> },
    { 
      path: '/dashboard',
      element: <Home />,
      children: [
        { path: '/dashboard', index: true, element: <Dashboard/> },
        { path: '/dashboard/chains', element: <Chains/> },
        { path: '/dashboard/chains/:chainid', element: <ChainsDetail/> },
      ],
    },
    // { path: '/home/:postId', element: <Home /> },
    { path: '*', element: <div>404</div>}
  ])
  return routes;
}

export default Routes;