import { Link, Outlet } from 'react-router-dom';
// import { StargateClient } from '@cosmjs/stargate';
// import { useDispatchStore } from '../../store/store';
// import httpClient from '../../utils/httpClient';
import home from '../../image/home.svg';
import chains from '../../image/chains.svg';
import logo from '../../image/logo.png';
import './index.css';

function Home() {
  // const dispatch = useDispatchStore();
  
  // window.onload = async () => {
  //   const chainJson = await httpClient('get', '/chain-registry/umee/chain.json')
  //   console.log("🚀 ~ file: home.js ~ line 20 ~ window.onload= ~ chainJson", chainJson)
  //   const rpcUrl =  chainJson.apis.rpc[2].address
  //   if (window.keplr) {
  //     const client = await StargateClient.connect(rpcUrl);
  //     const chainId = await client.getChainId();
  //     console.log("🚀 ~ file: home.js ~ line 27 ~ window.onload= ~ chainId", chainId)
  //     // const chainId = 'cosmoshub-4';
  //     // const chainId = 'umee-1';

  //     // Enabling before using the Keplr is recommended.
  //     // This method will ask the user whether to allow access if they haven't visited this website.
  //     // Also, it will request that the user unlock the wallet if the wallet is locked.
  //     await window.keplr.enable(chainId);

  //     const offlineSigner = window.keplr.getOfflineSigner(chainId);

  //     // You can get the address/public keys by `getAccounts` method.
  //     // It can return the array of address/public key.
  //     // But, currently, Keplr extension manages only one address/public key pair.
  //     // XXX: This line is needed to set the sender address for SigningCosmosClient.
  //     const accounts = await offlineSigner.getAccounts();
  //     console.log(
  //       '🚀 ~ file: home.js ~ line 28 ~ window.onload= ~ accounts',
  //       accounts,
  //       offlineSigner,
  //       await window.keplr.getKey('umee')
  //     );
  //     const balanceStake = await client.getBalanceStaked(accounts[0].address)
  //     const denom = balanceStake.denom;
  //     const balacne = await client.getBalance(accounts[0].address, denom)
  //     const validatorAddress = 'umeevaloper10nsytn9zmtfnhk37hqfe2h9v599ld3h5zs9fqj'
  //     const delegation = await client.getDelegation(accounts[0].address, validatorAddress)
  //     // const balance = await client.getBalance(accounts[0].address, )
  //     console.log("🚀 ~ file: home.js ~ line 49 ~ window.onload= ~ balance", balacne, balanceStake, delegation)
  //     dispatch({type: 'setWallet', payload: {
  //       address: accounts[0].address,
  //       addressStr: `${accounts[0].address.substring(
  //         0,
  //         8
  //       )}...${accounts[0].address.substring(accounts[0].address.length - 4)}`,
  //     }})
  //     // setWallet({
  //     //   ...wallet,
  //     //   address: accounts[0].address,
  //     //   addressStr: `${accounts[0].address.substring(
  //     //     0,
  //     //     8
  //     //   )}...${accounts[0].address.substring(accounts[0].address.length - 4)}`,
  //     // });

  //     // Initialize the gaia api with the offline signer that is injected by Keplr extension.
  //     // const rpc = "https://rpc.aphrodite.main.network.umee.cc"

  //     // const client = await StargateClient.connect(rpc)
  //     // console.log("With client, chain id:", await client.getChainId(), ", height:", await client.getHeight())

  //     // const cosm = new SigningCosmosClient(
  //     //   'https://lcd-umee.keplr.app',
  //     //   accounts[0].address,
  //     //   offlineSigner
  //     // );
  //     // console.log(cosm);
  //   }
  // };

  
  // useEffect(() => {

  // });
  return (
    <div className="App dark">
      {/* <Chains /> */}
      <div className="dark">
        <div className="flex flex-row w-full justify-center bg-white dark:bg-[#13182c]">
          <div className="flex flex-col">
            {/* sidebar */}
            <div className="flex flex-col min-w-[240px] justify-between z-10 xl:static sticky top-0 h-screen xl:max-h-full py-12 px-7 bg-white dark:bg-[#13182c] xl:animate-slide-right">
              <div className="flex flex-col">
                <div className=" mb-[calc(100vh*0.1)]">
                  <a href="/dashboard" className='flex flex-row items-center'>
                    <span className="box-border inline-block overflow-hidden border-0 m-0 p-0 relative max-w-full">
                      <span className="box-border block overflow-hidden border-0 m-0 p-0 relative max-w-full">
                        <img
                          className="block max-w-full bg-none border-none m-0 p-0"
                          alt=""
                          aria-hidden="true"
                          src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2748%27%20height=%2748%27/%3e"
                        />
                      </span>
                      <img
                        className="absolute top-0 left-0 box-border p-0 m-0 min-w-full min-h-full max-w-full max-h-full"
                        src={logo}
                        alt="logo"
                      />
                    </span>
                    <h2 className="font-sq font-semibold text-xl leading-5 ml-[14px] text-grey-600 dark:text-grey-50 whitespace-pre-line text-left">
                      Pelican Dashboard
                    </h2>
                  </a>
                </div>
                {/* 导航 */}
                <div className="flex flex-col gap-9 pl-2">
                  <Link
                    to="/dashboard"
                    className="flex flex-row items-center font-sq text-lg gap-5 hover:text-grey-550 dark:hover:text-grey-300 font-medium text-grey-500"
                  >
                    <img src={home} alt="home" />
                    Home
                  </Link>
                  {/* <a
                      className="flex flex-row items-center font-sq text-lg gap-5 hover:text-grey-550 dark:hover:text-grey-300 font-medium text-grey-500"
                      href="https://wallet.keplr.app/"
                    > */}
                  {/* </a> */}
                  <Link
                    to="/dashboard/chains"
                    className="flex flex-row items-center font-sq text-lg gap-5 hover:text-grey-550 dark:hover:text-grey-300 font-medium text-grey-500"
                  >
                    {/* <a
                        className="flex flex-row items-center font-sq text-lg gap-5 hover:text-grey-550 dark:hover:text-grey-300 font-medium text-grey-300"
                        href="https://wallet.keplr.app/chains"
                      > */}
                    <img src={chains} alt="chains" />
                    Chains
                    {/* </a> */}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* content */}
          <div className="page-wrapper pt-12 pb-16">
            {/* 头部 */}

            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
